const mergeClasses = (...classes: string[]): string => {
  const result = classes.reduce((p: string[], c: string) => {
    c.split(' ').filter(w => !!w).forEach(w => {
      if (!p.includes(w)) {
        if (w.match(/\(.*\)/g) != null) {
          const removing = w.replace(/\((.*)\)/, (m, p) => p);
          p = p.filter(v => v !== removing);
        } else {
          p.push(w);
        }
      }
    });
    return p;
  }, []);
  return result.join(' ');
};

export {
  mergeClasses,
};
