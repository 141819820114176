/**
 * Follow instructions from
 * - How to Use Nested Routes in React Router 6
 *      https://dev.to/tywenk/how-to-use-nested-routes-in-react-router-6-4jhd
 */
import React from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
  createRoutesFromElements,
} from 'react-router-dom';
// import ReactClass from './ReactClass';
// import ReactContext from './ReactContext';
// import ReactPage from './ReactPage';
import Home from './Home';
import { Posts, Post, PostNew, postLoader } from './Post';
import Layout from './Layout';
import CallbackPage from './CallbackPage';
import { Error } from './Error';
import { Group, Groups, groupLoader } from './Group';
import { GroupNew } from './GroupEdit';
import { User } from './User';
import { Placeholder } from './Placeholder';
import { UserLayout } from './UserLayout';

const ErrorBoundary = (): JSX.Element => {
  const error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
};

export enum Routes {
  USER = '/user',
  GROUP_NEW = '/user/group/new',
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Layout />} errorElement={<ErrorBoundary />}>
        <Route index element={<Home />} />
        <Route path="/error" element={<Error />} />
        {/* <Route path="/react-class" element={<ReactClass />}></Route> */}
        {/* <Route path="/react-context" element={<ReactContext />}></Route> */}
        {/* <Route path="react" element={<ReactPage />} /> */}
        <Route
          path="group"
          errorElement={(() => (
            <h1>Error</h1>
          ))()}
        >
          <Route index element={<Groups />} />
        </Route>
        <Route
          path="/group/:groupId"
          loader={groupLoader}
          element={<Group />}
        />
        <Route path="/group/:groupId/post" element={<Posts />} />
        <Route path="/group/:groupId/post/new" element={<PostNew />} />
        <Route
          path="/group/:groupId/post/:postId"
          loader={postLoader}
          element={<Post />}
        />
        <Route path="/callback" element={<CallbackPage />} />
        <Route path="/user" element={<UserLayout />}>
          <Route index element={<User />} />
          <Route path={Routes.GROUP_NEW} element={<GroupNew />} />
        </Route>
        <Route path="/settings" element={<Placeholder />} />
      </Route>
    </>
  )
);

const $RouterProvider = (): React.ReactElement => (
  <RouterProvider router={router} />
);

export default $RouterProvider;
