import React, {
  type FC,
  useState,
  useEffect,
  useRef,
  createContext,
} from 'react';
import { NewsItem } from './NewsItem';
import type { NewsItemType } from './types';

const POST_API_HOST = process.env.REACT_APP_AWS_U1_POST_HOST ?? 'localhost';

const fetchNews = async (): Promise<NewsItemType[]> => {
  // get news from API
  // @todo: 20231027 - load content from AWS API, fetch news posts
  // const response = await fetch('https://jsonplaceholder.typicode.com/posts');
  const response = await fetch(
    `${POST_API_HOST}/posts`
    // 'https://mm7l4kvvz1.execute-api.us-west-2.amazonaws.com/posts'
  );
  const newsList = await response.json();
  return newsList;
};

export const WindowSizeContext = createContext<{
  innerWidth: number;
  innerHeight: number;
}>({
  innerWidth: 0,
  innerHeight: 0,
});

const News: FC = () => {
  const [newsList, setNewsList] = useState<NewsItemType[]>([]);
  const [loadingImageSize, setLoadingImageSize] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    // get news from API
    try {
      fetchNews()
        .then((list) => {
          setNewsList(list);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getWindowSize = (): { innerWidth: number; innerHeight: number } => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const { width: imageNatureWidth, height: imageNatureHeight } =
    loadingImageSize;

  const imageRatio =
    imageNatureHeight !== 0 ? imageNatureWidth / imageNatureHeight : -1;

  const imageStyle = {
    width: `${windowSize.innerHeight * 0.3 * imageRatio}px`,
    height: `${windowSize.innerHeight * 0.3}px`,
  };

  useEffect(() => {
    const handleWindowResize = (): void => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [loadingImageSize]);

  const [closeAll, setCloseAll] = useState(false);

  if (newsList.length === 0) {
    return (
      <div
        // style={}
        className="animate-pulse mt-[20vh] absolute w-full flex flex-col justify-center"
      >
        <img
          ref={imgRef}
          src="https://u1asset.s3.us-west-2.amazonaws.com/maggie3.png"
          className="w-1/2 m-auto"
          alt="Loading..."
          onLoad={() => {
            setLoadingImageSize({
              width: imgRef.current?.naturalWidth ?? 0,
              height: imgRef.current?.naturalHeight ?? 0,
            });
          }}
          style={imageStyle}
        />
        加载中...
      </div>
    );
  }

  return (
    <div>
      <WindowSizeContext.Provider value={windowSize}>
        {newsList.map(({ id, title, body }) => (
          <NewsItem
            key={id}
            id={id}
            title={title}
            body={body}
            close={[closeAll, setCloseAll]}
          />
        ))}
      </WindowSizeContext.Provider>
    </div>
  );
};

export default News;
