import React, {
  type FC,
  // CSSProperties, useState, useEffect, useContext
  useCallback,
  useMemo,
} from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, useSlate, Slate, } from 'slate-react';
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement,
  type BaseEditor,
} from 'slate';
import { withHistory, } from 'slate-history';
import { useIntl, } from 'react-intl';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import CodeIcon from '@mui/icons-material/Code';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';

import { Button, Toolbar, } from './Buttons/SlateButtonIcon';

const HOTKEYS: Record<string, string> = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const LIST_TYPES = ['numbered-list', 'bulleted-list',];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify',];

export const RichTextEditor: FC = () => {
  const renderElement = useCallback((props: any) => <Element {...props} />, []);
  const renderLeaf = useCallback((props: any) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const intl = useIntl();

  return (
    <Slate editor={editor} value={initialValue}>
      <Toolbar>
        <MarkButton format="bold" icon="format_bold" >
          <FormatBoldIcon />
        </MarkButton>
        <MarkButton format="italic" icon="format_italic">
          <FormatItalicIcon />
        </MarkButton>
        <MarkButton format="underline" icon="format_underlined" >
          <FormatUnderlinedIcon />
        </MarkButton>
        <MarkButton format="code" icon="code" >
          <CodeIcon />
        </MarkButton>
        <BlockButton format="heading-one" icon="looks_one" >
          <LooksOneIcon />
        </BlockButton>
        <BlockButton format="heading-two" icon="looks_two" >
          <LooksTwoIcon />
        </BlockButton>
        <BlockButton format="block-quote" icon="format_quote" >
          <FormatQuoteIcon />
        </BlockButton>
        <BlockButton format="numbered-list" icon="format_list_numbered" >
          <FormatListNumberedIcon />
        </BlockButton>
        <BlockButton format="bulleted-list" icon="format_list_bulleted" >
          <FormatListBulletedIcon />
        </BlockButton>
        <BlockButton format="left" icon="format_align_left" >
          <FormatAlignLeftIcon />
        </BlockButton>
        <BlockButton format="center" icon="format_align_center" >
          <FormatAlignCenterIcon />
        </BlockButton>
        <BlockButton format="right" icon="format_align_right" >
          <FormatAlignRightIcon />
        </BlockButton>
        <BlockButton format="justify" icon="format_align_justify" >
          <FormatAlignJustifyIcon />
        </BlockButton>
      </Toolbar>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={intl.formatMessage({ id: 'user.group_desc_placeholder', })}
        spellCheck
        autoFocus
        onKeyDown={event => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event as any)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

const toggleBlock = (editor: BaseEditor, format: string): void => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n: any) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes((n as any).type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement> & {
    align?: string | undefined
    type?: string | undefined
  };
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [], };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: BaseEditor, format: string): void => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor: BaseEditor, format: string, blockType = 'type'): boolean => {
  const { selection, } = editor;
  if (selection == null) return false;

  const [match,] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: any) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        (n as any)[blockType] === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor: BaseEditor, format: string): boolean => {
  const marks = Editor.marks(editor) as any;
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element, }: any): JSX.Element => {
  const style = { textAlign: element.align, };
  switch (element.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes} className="list-disc ml-4">
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes} className="ml-4 list-decimal">
          {children}
        </ol>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};

const Leaf = ({ attributes, children, leaf, }: any): JSX.Element => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export const BlockButton = ({
  format,
  icon,
  children,
}: any): React.ReactElement => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
      )}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {/* <Icon>{icon}</Icon> */}
      { children }
    </Button>
  );
};

export const MarkButton = ({
  format,
  icon,
  classes,
  children,
}: any): React.ReactElement => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: MouseEvent) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      className={classes}
    >
      {/* <Icon>{icon}</Icon> */}
      {children}
    </Button>
  );
};

const initialValue: any = [
  {
    type: 'paragraph',
    children: [
      { text: 'This is editable ', },
      { text: 'rich', bold: true, },
      { text: ' text, ', },
      { text: 'much', italic: true, },
      { text: ' better than a ', },
      { text: '<textarea>', code: true, },
      { text: '!', },
    ],
  },
  {
    type: 'paragraph',
    children: [
      {
        text:
          "Since it's rich text, you can do things like turn a selection of text ",
      },
      { text: 'bold', bold: true, },
      {
        text:
          ', or add a semantically rendered block quote in the middle of the page, like this:',
      },
    ],
  },
  {
    type: 'block-quote',
    children: [{ text: 'A wise quote.', },],
  },
  {
    type: 'paragraph',
    align: 'center',
    children: [{ text: 'Try it out for yourself!', },],
  },
];
