/**
 * Singleton store providing data to post components.
 *
 */
import type { PostType, GroupType, StoreInterface, } from './types';

export const getStoreItem = (
  key: string,
  type: 'local' | 'session' = 'local',
  defaultValue: any = {}
): any => {
  let value = defaultValue;
  try {
    if (typeof window !== 'undefined') {
      const store =
        type === 'local' ? window.localStorage : window.sessionStorage;
      value = JSON.parse(store.getItem(key) ?? JSON.stringify(defaultValue));
    }
  } catch (e) {
    console.error(`Can not read "${key}" from session storage.`, e);
  }
  return value;
};

export const setStoreItem = (
  key: string,
  val: any,
  type: 'local' | 'session' = 'local'
): void => {
  try {
    if (typeof window !== 'undefined') {
      const store =
        type === 'local' ? window.localStorage : window.sessionStorage;
      store.setItem(key, JSON.stringify(val));
    }
  } catch (e) {
    console.error(`Can't write "${key}" from ${type} storage.`, e);
  }
};

export const removeStoreItem = (
  key: string,
  type: 'local' | 'session' = 'local'
): void => {
  try {
    if (typeof window !== 'undefined') {
      const store =
        type === 'local' ? window.localStorage : window.sessionStorage;
      store.removeItem(key);
    }
  } catch (e) {
    console.error(`Can't remove "${key}" from ${type} storage.`, e);
  }
};

const localStorageSize = (): string => {
  let _lsTotal = 0;
  let _xLen;
  let _x: string;
  for (_x in localStorage) {
    if (!Object.prototype.hasOwnProperty.call(localStorage, _x)) continue;
    _xLen = ((localStorage[_x] as string).length + _x.length) * 2;
    _lsTotal += _xLen;
  }
  return (_lsTotal / 1024).toFixed(2);
};

export class Store implements StoreInterface {
  posts = new Map();

  groups = new Map<number, GroupType>();

  constructor () {
    console.log('Store constructor');
  }

  addPost (post: PostType | PostType[]): void {
    if (Array.isArray(post)) {
      post.forEach((p) => {
        this.posts.set(p.id, p);
      });
    } else {
      this.posts.set(post.id, post);
    }
  }

  addGroup (group: GroupType | GroupType[]): void {
    if (Array.isArray(group)) {
      group.forEach((g) => {
        this.groups.set(g.id, g);
      });
    } else {
      this.groups.set(group.id, group);
    }
  }

  addGroupObj (group: Record<number, GroupType>): void {
    Object.keys(group).forEach((k) => {
      const id = parseInt(k);
      this.groups.set(id, group[id]);
    });
  }

  getGroups (): Map<number, GroupType> {
    return this.groups;
  }

  getGroup (id: number): GroupType | undefined {
    return this.groups.get(id);
  }

  getGroupsSize (): number {
    return this.groups.size;
  }

  dumpGroups = (): void => {
    this.groups.forEach((v, k) => {
      console.log(k, v.subject);
    });
  };

  getWindowStoreItem = (key: string): any => getStoreItem(key);

  setWindowStoreItem = (key: string, group: any): void => {
    this.addGroup(group);
    setStoreItem(key, group);
  };

  removeWindowStoreItem = (key: string): void => { removeStoreItem(key); };

  static localStorageSize = localStorageSize;
}

const store = new Store();

export default store;
