import React, { type FC, useState, useRef, useEffect, useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import { marked } from 'marked';
import { WindowSizeContext } from './News';
import type { NewsItemType } from './types';

const itemTitleClasses = 'w-full text-xl text-left m-0';
const pClasses = 'text-lg text-gray-500 text-left m-2';

/**
 * NewsItem represents a single news post. It should be fixed to the top of the
 * screen with title and body.
 */
export const NewsItem: FC<NewsItemType> = (props: NewsItemType) => {
  const { id, close } = props;
  const [closeAll, setCloseAll] = close;
  const [active, setActive] = useState(false);
  const [summaryHeight, setSummaryHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current !== null) {
      setSummaryHeight(ref.current.clientHeight);
    }
    if (!active) {
      setCloseAll(true);
    }
  }, []);

  const { innerHeight } = useContext(WindowSizeContext);
  // console.log('summaryHeight', summaryHeight);

  const summaryId = `news-item-header-${id}`;
  console.log('rendered NewsItem', active);
  const randomInvert = Math.floor(Math.random() * 100);
  const clickHandler = (): void => {
    // close all other news items
    setActive(!active);
  };
  const itemPosition = active ? 'absolute' : 'relative';
  const itemSummaryPosition = active ? 'fixed' : 'relative';
  const expanded = active;
  console.log('expanded', expanded, active, closeAll, innerHeight);
  return (
    <Accordion
      TransitionProps={{ timeout: 0 }}
      expanded={expanded}
      sx={{
        margin: '0 0 0.7rem 0',
        boxShadow: 'none',
        border: 0,
        backgroundColor: active ? 'white' : 'transparent',
        top: 0,
        left: 0,
        zIndex: active ? 100 : 'auto',
        boxSizing: 'border-box',
        position: itemPosition,
        '&.Mui-expanded': {
          margin: 0,
          boxShadow: 'none',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
          margin: '0px',
          paddingRight: '10px',
          minHeight: 'auto',
        },
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={active ? <CancelIcon sx={{ color: 'red' }} /> : null}
        aria-controls="panel1a-content"
        id={summaryId}
        onClick={clickHandler}
        ref={ref}
        sx={{
          position: itemSummaryPosition,
          border: '0px',
          padding: '0px',
          minHeight: 'auto',
          margin: '0px',
          transitionDelay: '0ms',
          // boxShadow: active ? '0px 5px 25px black' : 'none',
          backgroundColor: active ? '#ccc' : 'transparent',
          '& .MuiAccordionSummary-content': {
            margin: '0px',
            padding: '0px',
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0px',
            padding: '0px',
            minHeight: 'auto',
          },
        }}
      >
        <Typography
          sx={{
            fontSize: '1.2rem',
            margin: 0,
            padding: 0,
            lineHeight: 'auto',
            display: 'flex',
            color: active ? 'black' : 'blue',
          }}
          className={itemTitleClasses}
        >
          <img
            src="https://u1asset.s3.us-west-2.amazonaws.com/placeholder.svg"
            className="w-8 rounded-full h-8 mr-2"
            style={{
              filter: `invert(${randomInvert}%) sepia(243%) saturate(1576%) hue-rotate(-21deg) brightness(137%) contrast(73%)`,
            }}
            alt={props.title}
          />
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: active ? '#fff' : 'transparent',
          position: itemSummaryPosition,
          marginTop: summaryHeight !== 0 ? `${summaryHeight}px` : 0,
          padding: 0,
          border: active ? '8px solid #ccc' : '0px',
          overflow: 'scroll',
          height: active ? `${innerHeight - summaryHeight}px` : 'auto',
        }}
      >
        <Typography
          className={pClasses}
          sx={{ fontSize: '1.2rem', '& p': { margin: '0.4rem 0.6rem 0.8rem' } }}
          dangerouslySetInnerHTML={{ __html: marked(props.body) }}
        />
      </AccordionDetails>
    </Accordion>
  );
};
