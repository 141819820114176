import React from 'react';
import Menu from './Menu';

export const Placeholder = () => {
  return (
    <>
      <Menu />
      <div className="content-layout">
        <h1 id="page-title" className="content__title">
          Setting Page
        </h1>
      </div>
    </>
  );
};
