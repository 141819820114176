import React, { FC } from 'react';
import News from './News';
// mui Learning resources
// https://mui.com/material-ui/getting-started/learn/
// import Button from '@mui/material/Button';

// @todo: 20230923 - load content from API, fetch news
const DashBoard: FC = () => {
  return (
    <>
      {/* <Button variant="contained">Hello world</Button> */}
      <div className="m-0 border-0 p-2 text-center">
        <News />
      </div>
    </>
  );
};

// DashBoard.displayName = 'U1_Dashboard_Again';

export default DashBoard;
