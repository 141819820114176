import React from 'react';
import { Outlet, } from 'react-router-dom';
import { Auth0ProviderWithNavigate, } from '../auth0-provider-with-navigate';

const Container = ({ children, }: any) => {
  const className = 'pb-40 bg-white';
  return (
    <div className={className}>{children}</div>
  );
};

const Layout = ({ children, }: any) => {
  return (
    <>
      <Auth0ProviderWithNavigate>
        <Container>
          <Outlet />
        </Container>
      </Auth0ProviderWithNavigate>
    </>
  );
};
export default Layout;
