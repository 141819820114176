import { useAuth0, } from '@auth0/auth0-react';
import React, {
  type FC,
} from 'react';
import {
  Link,
} from 'react-router-dom';
import {
  LoginButton,
  SignupButton,
} from './Buttons/LoginButton';
import { FormattedMessage, } from 'react-intl';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';

export const User: FC = () => {
  const { user, } = useAuth0();

  console.log('User:', user);
  if (user == null) {
    return (
      <div className="m-4 flex flex-col justify-around">
        <div className="m-2 border-2 border-slate-100 p-2 text-center">
          <LoginButton />
          <p className="my-2">
            <FormattedMessage id="user.login_desc" />
          </p>
        </div>
        <div className="m-2 border-2 border-slate-100 p-2 text-center">
          <SignupButton />
          <p className="my-2">
            <FormattedMessage id="user.sign_up_desc" />
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="content-layout">
        <div className="rounded border-2 m-4">
          <h2 id="group-title" className="content__title flex justify-center text-lg font-bold">
            <FormattedMessage id={'user.user_title'} />
          </h2>
          <span className="profile__description">{user.email}</span>
        </div>
        <div className="rounded border-2 m-4">
          <h2 id="group-title" className="content__title flex justify-center text-lg font-bold">
            <FormattedMessage id={'user.group_title'} />
          </h2>
          <div>
            <ul className="m-4">
              <li>
                <Link to='/user/group/new' className="underline text-blue-700">
                  <MapsUgcIcon />
                  <FormattedMessage id={'user.group_new'} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
