/**
 * - Themes are loaded from config
 * - For language intl. process, check:
 *    https://formatjs.io/docs/getting-started/message-declaration
 *    https://medium.com/i18n-and-l10n-resources-for-developers/best-libraries-for-react-i18n-5bc37c20bd63
 */
import React, { useEffect, useState, useCallback } from 'react';
import { IntlProvider } from 'react-intl';
import i18nConfig from './lang/i18nConfig';
import TagManager from 'react-gtm-module';

import Page from './components/Page';
import { ThemeProvider } from './components/Theme';
import { uFetch } from './utils/remote';
// import logo from './logo.svg';
import './App.css';
import { initAppState, appStateContext } from './Context';
import type { AppStateType } from './types/appTypes';

const tagManagerArgs = {
  gtmId: 'G-8YCFCGX1XT',
};

TagManager.initialize(tagManagerArgs);

// defaults
const initAppUrl = `${process.env.REACT_APP_API_HOST ?? ''}/${
  process.env.REACT_APP_API_PATH ?? ''
}/${process.env.REACT_APP_API_INIT_ENDPOINT ?? ''}?api-key=foo`;

// @todo: type import() without any.
interface LocaleData {
  default: {
    messages: LocaleMessages;
  } & any;
}

type LocaleMessages = Record<string, string>;

// @todo: clean up demo code
const getConfig = (): any => ({
  themes: {
    color: 'white',
    bgColor: 'green',
  },
});

const initApp = async (
  setState: React.Dispatch<React.SetStateAction<AppStateType>>
): Promise<void> => {
  try {
    const response = await uFetch(initAppUrl);
    const data = await response.json();
    console.log('>>>>>>>>', data);
    setState((prevState) => ({
      ...prevState,
      ...data,
    }));
  } catch (error: any) {
    // @todo: handler this error
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    console.log(`initApp error: ${error}`);
  }
};

const loadLocaleData = async (locale: string): Promise<LocaleData> => {
  switch (locale) {
    case 'zh-CN':
      return await import('./lang/zh-CN.json');
    default:
      return await import('./lang/en-US.json');
  }
};
const locale = 'zh-CN';

/**
 * App component
 *
 * @returns JSX
 */
const App = (): React.ReactElement => {
  // app state
  const [appState, SetAppState] = useState<AppStateType>(initAppState);
  console.log('App rendered');
  const [localeMessages, SetLocaleMessages] = useState<LocaleMessages>(
    i18nConfig.messages
  );

  const getLocaleData = useCallback(async () => {
    try {
      const localeData = await loadLocaleData(locale);
      // console.log(">>>>>>>>>", localeData.default.messages);
      return localeData.default.messages;
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    const localeData = getLocaleData();
    void localeData.then((v) => {
      SetLocaleMessages(v);
    });
  }, [getLocaleData]);

  useEffect(() => {
    const run = async (): Promise<void> => {
      await initApp(SetAppState);
    };
    void run();
  }, []);

  return (
    <>
      <IntlProvider
        locale={locale}
        defaultLocale={locale}
        messages={localeMessages}
      >
        <ThemeProvider theme={getConfig().themes}>
          <appStateContext.Provider value={appState}>
            <Page />
          </appStateContext.Provider>
        </ThemeProvider>
      </IntlProvider>
      {/* <p>{JSON.stringify(appState)}</p> */}
    </>
  );
};

export default App;
