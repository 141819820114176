/**
 * @todo:
 *   - fetch user data with user session token.
 *      https://create-react-app.dev/docs/adding-custom-environment-variables/
 */
import { pick, } from 'lodash';
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse, AxiosError, } from 'axios';
import type { ApiResponse, AppError, } from '../types/appTypes';

// import * as dotenv from 'dotenv';
// dotenv.config();
const options = (method: string, data: any): Record<string, any> => ({
  method, // *GET, POST, PUT, DELETE, etc.
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Key': 'eTaW6dIh+Hb+DOoHkxJICA==GynxvPSnFXyX5VQC',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  body: JSON.stringify(data), // body data type must match "Content-Type" header
});

const uFetch = async (url = '', method: string = 'GET', data = {}): Promise<any> => {
  // Default options are marked with *
  return await fetch(url, (method === 'GET') ? pick(options(method, data), ['method', 'headers',]) : options(method, data));
  // return response.json(); // parses JSON response into native JavaScript objects
};

const remoteApi = async (options: {
  config: AxiosRequestConfig
}): Promise<ApiResponse> => {
  try {
    const response: AxiosResponse = await axios(options.config);
    const { data, } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error as AxiosError;

      const { response, } = axiosError;

      let message = 'http request failed';

      if (response?.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response?.data && (response.data as AppError).message) {
        message = (response.data as AppError).message;
      }

      return {
        data: null,
        error: {
          name: 'Axios Error',
          message,
        },
      };
    }

    return {
      data: null,
      error: {
        name: 'Axios Error',
        message: (error as Error).message,
      },
    };
  }
};

export {
  uFetch,
  remoteApi,
};
