/* eslint-disable multiline-ternary */
import React, { type FC, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Button } from '@mui/base/Button';
import { Input as BaseInput, InputProps } from '@mui/base/Input';
import { styled } from '@mui/system';

const Input = React.forwardRef(function CustomInput(
  props: InputProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return <BaseInput slots={{ input: InputElement }} {...props} ref={ref} />;
});

const InputElement = styled('input')(
  ({ theme }) => `
  width: 100%;
  padding: ${theme.spacing(1)};
`
);

export type PostEditorPropsType = {
  name?: string;
};

const PostEditor: FC<PostEditorPropsType> = (props: PostEditorPropsType) => {
  const [active, setActive] = useState(false);
  const clickHandler = (): void => {
    console.log('clicked');
    setActive((active) => !active);
  };

  const formClassName =
    'flex flex-col justify-center border-2 rounded-md mt-4 p-1';

  return (
    <div className="flex flex-col border-2 rounded-md p-1">
      <div className="flex flex-row items-center">
        <button className="m-2 shadow-md" onClick={clickHandler}>
          <AddCircleIcon
            sx={{ color: active ? 'grey' : 'green', fontSize: '4em' }}
          />
        </button>
        <div>
          <span className="font-bold">发布信息</span>: 请点击
          <AddCircleIcon sx={{ color: 'green' }} />
          发布信息。 信息内容可以包括新闻，公告，广告，招聘，求职，社区活动。
        </div>
      </div>

      <div className={!active ? 'hidden' : `block ${formClassName}`}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
          className="flex flex-col"
        >
          <Input
            placeholder="请在此处输入标题。"
            required
            className="block w-full mb-2"
          />
          <TextareaAutosize
            placeholder="请在此处输入内容。"
            required
            minRows={3}
            className="block w-full p-2"
          />
          <Button
            className="m-4 rounded-md bg-green-700 text-white px-4 py-2"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default PostEditor;
