/**
 * Page component
 */
import React from 'react';
import Route from './Route';

const Page = (): React.ReactElement => {
  return (
    <Route />
  );
};

export default Page;
