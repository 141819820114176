import React from 'react';
import { useAuth0, } from '@auth0/auth0-react';
import { FormattedMessage, useIntl, } from 'react-intl';
import { useForm, type SubmitHandler, } from 'react-hook-form';
import { RichTextEditor, } from './Editor';
import SaveIcon from '@mui/icons-material/Save';
import ReportIcon from '@mui/icons-material/Report';
import { remoteApi, } from '../utils/remote';
import type { AxiosRequestConfig, } from 'axios';
import type { ApiResponse, } from '../types/appTypes';

interface Inputs {
  groupName: string
}

const groupsUrl = process.env.REACT_APP_API_GROUPS_URL ?? 'https://u1e.ucxp.com/api/groups?api-key=foo';

export const saveGroup = async (
  accessToken: string,
  data: any
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${groupsUrl}`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data,
  };

  const { data: resultData, error, } = (await remoteApi({ config, }));

  return {
    data: resultData,
    error,
  };
};

const GroupNew = (): React.ReactElement => {
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors, },
  } = useForm<Inputs>();

  const { getAccessTokenSilently, } = useAuth0();

  const onSubmit: SubmitHandler<Inputs> = async (data): Promise<void> => {
    try {
      const accessToken = await getAccessTokenSilently();
      console.log(accessToken, 'accessToken');

      // await uFetch(groupsUrl, 'POST', data);
      await saveGroup(
        accessToken,
        data
      );
    } catch (error) {
      console.log(error);
    }
    console.log(data);
  };

  const intl = useIntl();
  const submit = intl.formatMessage({ id: 'user.group_new_form_submit', });
  const groupName = intl.formatMessage({ id: 'user.group_name', });

  return (
    <div className="mx-2 mt-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* https://react-hook-form.com/get-started */}
        {/* include validation with required or other standard HTML validation rules */}
        {/* <input {...register("exampleRequired", { required: true })} /> */}
        {/* errors will return when field validation fails  */}
        <section className="mb-4">
          <h2 className="font-bold">
            1. <FormattedMessage id="user.group_name" />
            <span className="text-xs text-slate-400"> (<FormattedMessage id="user.group_name_max" />)</span>
          </h2>
          <div className="border-2 p-1 border-slate-200">
            {/* register your input into the hook by invoking the "register" function */}
            <input defaultValue="test" {...register('groupName', { required: true, })}
              className="w-full outline-0"
              placeholder={groupName}
              maxLength={16}
            />
          </div>
          {
            (errors.groupName != null) &&
            <span className="text-red-500 text-xs">
              <ReportIcon />
              <FormattedMessage id="user.group_name_required" />
            </span>
          }
        </section>
        <section className="mb-4">
          <h2 className="font-bold">
            2. <FormattedMessage id={'user.group_desc'} />
          </h2>
          <div className="border-2 p-1 border-slate-200">
            <RichTextEditor />
          </div>
          <div className="text-center mt-4">
            <button
              type="submit"
              className="inline-block m-auto p-4 rounded-lg bg-slate-100 text-center border-2"
            >
              <SaveIcon />
              {submit}
            </button>
          </div>
        </section>
      </form>
    </div>
  );
};

export { GroupNew, };
