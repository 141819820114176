import { createContext, } from 'react';
import { type AppStateType, } from './types/appTypes';

const initAppState: AppStateType = {
  lastUpdate: 0,
  groupThreshold: 30,
};
const appStateContext = createContext<AppStateType>(initAppState);

export {
  appStateContext,
  initAppState,
};
