/* eslint-disable multiline-ternary */
import React, { type FC, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { SvgIcon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import CategoryIcon from '@mui/icons-material/Category';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import PostEditor from './PostEditor';

export interface ControlPropsType {
  controlClasses?: string;
  data?: Record<string, unknown>;
  Icon?: typeof SvgIcon;
}
const Control: FC<ControlPropsType> = (props: ControlPropsType) => {
  const [active, setActive] = useState(false);
  const clickHandler = (): void => {
    console.log('clicked');
    setActive((active) => !active);
  };
  const { Icon } = props;
  const defaultControlClasses = 'text-black';

  const Button: FC = () =>
    Icon != null ? <Icon /> : <MenuIcon className={defaultControlClasses} />;

  // console.log(Button);

  return (
    <>
      <div className="cursor-pointer" onClick={clickHandler}>
        {active ? <CloseIcon /> : <Button />}
      </div>
      <div className={active ? 'block' : 'hidden'}>
        <div className="flex flex-col absolute h-screen w-screen border-2 top-0 left-0 bg-slate-300 z-10">
          <div className="flex flex-row justify-end">
            <button className="m-4" onClick={clickHandler}>
              {active ? <CancelIcon sx={{ color: 'red' }} /> : null}
            </button>
          </div>
          <div className="grow m-4">
            <div className="flex flex-col">
              <div className="flex flex-col my-4">
                <div className="relative">
                  <SearchIcon
                    className="absolute top-0 left-0 ml-1"
                    sx={{ height: '100%' }}
                  />
                  <input
                    type="text"
                    id="search-box"
                    className="w-full block pl-8 py-2 rounded-md"
                    placeholder="搜索关键词，例如 “经济，娱乐，体育”"
                  />
                </div>
              </div>
              <div className="flex flex-col my-4">
                <div className="flex flex-row border-2 rounded-md p-1">
                  <button className="m-2 shadow-md" onClick={() => {}}>
                    <CategoryIcon
                      sx={{
                        color: active ? 'green' : 'green',
                        fontSize: '4em',
                      }}
                    />
                  </button>
                  <div>分类信息: </div>
                </div>
              </div>
              <PostEditor />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Control;
