import React, {
  type FC, type CSSProperties, useState, useEffect, useContext,
} from 'react';
import {
  Outlet,
  useParams,
  Link,
  useLoaderData,
  type LoaderFunctionArgs,
} from 'react-router-dom';
import { useAuth0, } from '@auth0/auth0-react';
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MenuIcon from '@mui/icons-material/Menu';
import _ from 'lodash';
import CircleLoader from 'react-spinners/CircleLoader';
import { uFetch, } from '../utils/remote';
import store from './Store';
import Header from './Header';
import Menu, { BottomBrowseMenu, } from './Menu';
import type { GroupType, PostType, } from './types';
import type { HeaderPropsType, } from './Header';
import constants from '../constant';
import { appStateContext, } from '../Context';

// @todo: load from config
// const groupsUrl = "http://localhost:3001/api/groups?api-key=foo";
// const groupUrl = "http://localhost:3001/api/group/{GROUPID}?api-key=foo";
// const groupsUrl = "https://u1e.ucxp.com/api/groups?api-key=foo";
// const groupUrl = "https://u1e.ucxp.com/api/group/{GROUPID}?api-key=foo";

const groupsUrl = process.env.REACT_APP_API_GROUPS_URL ?? 'https://u1e.ucxp.com/api/groups?api-key=foo';
const groupUrl = process.env.REACT_APP_API_GROUP_POST_URL ?? 'https://u1e.ucxp.com/api/group/{GROUPID}?api-key=foo';
// console.log('>>>>>>>>>>>>>>>>>>>>>>', groupsUrl, process.env.REACT_APP_API_GROUPS_URL);
const groupListItemBodyStyle = 'text-xs max-h-10 overflow-hidden';

const getGroupInfo = (id: number) => {
  const groupInfo = store.getGroup(id);
  return {
    groupName: groupInfo?.subject,
  };
};

const withGroupHeader = (Component: FC<HeaderPropsType>) => {
  const WithGroupHeader = (props: HeaderPropsType) => {
    return (
      <div className="flex items-center bg-slate-200 px-2">
        <div className="w-12 text-center hover:text-red-500">
          <Link to={'/group'}
            className="flex items-center justify-center w-10 h-10 border-2  border-slate-100 rounded-full"
          >
            <KeyboardArrowLeftIcon />
          </Link>
        </div>
        <Component {...props} />
        <div className="w-12">
          <MenuIcon />
        </div>
      </div>
    );
  };

  return WithGroupHeader;
};

const GroupHeader = withGroupHeader(Header);

const PostListItem: FC<PostType> = ({
  id,
  groupId,
  subject,
}) => {
  return (
    <Link to={`/group/${groupId}/post/${id}`}>{subject}</Link>
  );
};

const Group: FC<Partial<GroupType>> = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('Scroll to top');
  }, []);

  const { groupId, } = useParams();
  if (!groupId) {
    throw new Error('Failed to find group id from route.');
  }
  const groupInfo = useLoaderData() as GroupType;
  store.addGroup(groupInfo);
  const {
    posts = [],
  } = groupInfo;
  const PostElements = posts.map((post) => {
    return (
      <li className="mb-2" key={post.id}>
        <PostListItem {...post} />
      </li>
    );
  });

  const { groupName, } = getGroupInfo(parseInt(groupId));
  const { isAuthenticated, isLoading, user, } = useAuth0();
  console.log(isAuthenticated, 'xxxxxxx', isLoading, user);

  return (
    <>
      <GroupHeader
        headerClasses="bg-transparent w-full text-center p-4"
        headerMessageId="general.group_header"
        values={{ name: groupName, }}
      />
      {/* {isAuthenticated && (
        <p>Logged in</p>
      )} */}
      <div className="w-full">
        <ul className="ml-4 mt-2 mb-24 list-disc">
          {PostElements}
        </ul>
      </div>
      {/* <Outlet /> */}
      <BottomBrowseMenu
        classes='(h-20) h-14 items-center (bg-slate-300) bg-slate-200 justify-between'
        backLink={'/group/'}
      />
    </>
  );
};

const groupListItemH3Style = 'mt-2';
const GroupListItemTitle: FC<{
  title: string | undefined
  repeat: number
}> = (props) => {
  const stars: any[] = [];
  _.times(props.repeat, (index) => {
    stars.push(<StarIcon key={index} fontSize="small" />);
  });
  return (
    <div className="flex w-full">
      <h3 className={groupListItemH3Style}>{props.title}
        <span className="text-red-500 inline-block scale-50">
          {stars}
        </span>
      </h3>
    </div>
  );
};

const override: CSSProperties = {
  display: 'block',
  position: 'absolute',
  top: '5%',
  right: '5%',
  margin: '0 auto',
  borderColor: 'red',
};

const groupListItem = 'relative h-20 overflow-hidden';
const groupListItemLinkStyle = 'flex flex-row w-full';
const groupListItemImageStyle = 'w-14 h-14 rounded-full border-2 m-1 p-0 inline-block';
const GroupListItem: FC<Partial<GroupType>> = (props) => {
  const [loading, setLoading,] = useState(false);
  const color = '#ccc';
  return (
    <li
      className={groupListItem}
      key={props.id}
      onClick={() => { setLoading(true); }}
    >
      <Link
        to={`/group/${props.id}`}
        className={groupListItemLinkStyle}>
        <img
          alt={props.subject}
          src={props.icon}
          className={groupListItemImageStyle}
        />
        <div className="inline-block">
          <GroupListItemTitle
            title={`${props?.subject} | ${props.id}`}
            repeat={props?.stars ?? 0}
          />
          <p className={groupListItemBodyStyle}>{props?.body}</p>
        </div>
      </Link>
      <CircleLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={30}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </li>
  );
};

// @todo: move to type file
type GroupData = Record<number, GroupType>;;

/**
 * Groups
 *
 * @returns
 */
const Groups: React.FC = () => {
  const appState = useContext(appStateContext);

  // react dom route loader to get data from API or localStore
  // const groupData = useLoaderData() as GroupData;

  const [groupsData, setGroups,] = useState<GroupData>({});
  console.log('Groups data: ', groupsData);

  useEffect(() => {
    const { groupThreshold, } = appState;
    console.log('Groups >> groupThreshold', groupThreshold);

    groupsLoader(groupThreshold, setGroups);
  }, [appState,]);

  store.addGroupObj(groupsData);

  const groupList = Object.keys(groupsData).filter((v) => {
    return parseInt(v) > 0;
  });
  const GroupElements = groupList.map((groupId) => {
    return <GroupListItem {...groupsData[parseInt(groupId)]} key={groupId} />;
  });

  const color = '#ccc';
  const loading = true;

  return (
    <div>
      <Menu />
      <Header
        headerMessageId="general.groups_header"
        values={{ name: new Date().toDateString(), }}
      />
      <div>
        {
          (Object.keys(groupsData).length > 0)
            ? (<ul>{GroupElements}</ul>)
            : (<CircleLoader
              color={color}
              loading={loading}
              cssOverride={{}}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
              )
        }
      </div>
      <Outlet />
    </div>
  );
};

// const getGroupPost = (groupId: number): PostType[] => {
//   const posts: PostType[] = [];
//   const storedGroups = store.getWindowStoreItem(constants.STORE_GROUPS_NAME);
//   // @todo: get from store, if not in store, then load from remote.
//   return posts;
// };

/**
 * react router: loader for group route.
 *
 * @param param0
 * @returns
 */
const groupLoader = async ({ params, }: LoaderFunctionArgs) => {
  const groupId = parseInt(params.groupId ?? '0');
  // load from window storage before fetch from remote.
  // check in local storage first, before fetching.
  const storedGroups = store.getWindowStoreItem(constants.STORE_GROUPS_NAME);
  const group = storedGroups[groupId];
  if (group?.posts?.length) {
    console.log('group from browser storage ...');
    // @todo: get posts of group
    const { posts, } = group;
    return {
      group,
      posts,
    };
  } else {
    const group$ = await fetchGroup(groupId);
    const { group, posts, } = group$;
    return {
      ...group,
      posts,
    };
  }
};

/**
 * fetch group from remote if no local stored group found.
 * set group object (keyed groups) into local storage.
 * @returns
 */
const groupsLoader = async (groupThreshold: number, setGroups: React.Dispatch<React.SetStateAction<GroupData>>) => {
  // load from window storage before fetch from remote.
  // check in local storage first, before fetching.
  const storedGroups = store.getWindowStoreItem(constants.STORE_GROUPS_NAME);
  const count = Object.keys(storedGroups).length;
  if (count >= groupThreshold) {
    console.log('groups from browser storage ...');
    setGroups(storedGroups);
  } else {
    const groupData = await fetchGroups();
    setGroups(groupData);
  }
};

const fetchGroups = async () => {
  console.log('groups from fetch ...');
  const response = await uFetch(groupsUrl);
  const data = await response.json();
  // save to browser storage.
  const groups = (await data) as GroupType[];
  const groupObj: Record<number, GroupType> = {};
  groups.forEach((g) => {
    groupObj[g.id] = g;
  });
  store.setWindowStoreItem(constants.STORE_GROUPS_NAME, groupObj);
  return groupObj;
};

const fetchGroup = async (id: number) => {
  console.log('group from fetch ...');
  const response = await uFetch(groupUrl.replace('{GROUPID}', String(id)));
  const data = await response.json();
  // save group and its posts to browser storage.
  const groupData = (await data) as {
    group: GroupType
    posts: PostType
  };
  // check if it's already in window store
  const storedGroups = store.getWindowStoreItem(constants.STORE_GROUPS_NAME);
  storedGroups[groupData.group.id] = {
    ...groupData.group,
    posts: groupData.posts,
  };
  store.setWindowStoreItem(constants.STORE_GROUPS_NAME, storedGroups);
  return groupData;
};

export {
  Group,
  Groups,
  groupLoader,
  groupsLoader,
};
