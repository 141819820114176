import { useAuth0, } from '@auth0/auth0-react';
import React from 'react';
import { FormattedMessage, } from 'react-intl';

export const LoginButton = (): React.ReactElement => {
  const { loginWithRedirect, } = useAuth0();

  const handleLogin = async (): Promise<void> => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
      authorizationParams: {
        prompt: 'login',
      },
    });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <button className="button__login border-2 bg-slate-200 py-1 px-3 rounded border-slate-400" onClick={handleLogin}>
      <FormattedMessage id="user.login" />
    </button>
  );
};

export const LogoutButton = ({ children, }: any): React.ReactElement => {
  const { logout, } = useAuth0();
  const handleLogout = (): any => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <button className="button__logout" onClick={handleLogout}>
      {children}
    </button>
  );
};

export const SignupButton = (): React.ReactElement => {
  const { loginWithRedirect, } = useAuth0();

  const handleSignUp = async (): Promise<void> => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
      authorizationParams: {
        prompt: 'login',
      },
      // authorizationParams: {
      //   screen_hint: "signup",
      // },
    });
  };

  return (
    <button className="button__sign-up border-2 bg-slate-200 py-1 px-3 rounded border-slate-400" onClick={handleSignUp} >
      <FormattedMessage id="user.sign_up" />
    </button>
  );
};
