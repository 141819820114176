import React, {
  type FC,
} from 'react';
import { useAuth0, } from '@auth0/auth0-react';
import {
  Outlet,
  useLocation,
} from 'react-router-dom';
import { LogoutButton, } from './Buttons/LoginButton';
import { FormattedMessage, } from 'react-intl';
import Header from './Header';
import Menu from './Menu';
import { Routes, } from './Route';
import type { HeaderPropsType, } from './Header';
import LogoutIcon from '@mui/icons-material/Logout';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';

const withUserHeader = (Component: FC<HeaderPropsType>): FC => {
  const WithUserHeader = (props: HeaderPropsType): React.ReactElement => {
    const { user, } = useAuth0();
    const { pathname, } = useLocation();
    const { headerMessageId, ...rest } = props;
    let userHeaderMessageId: string;
    const values: Record<string, string> = {};
    let icon;

    switch (pathname) {
      case Routes.GROUP_NEW:
        userHeaderMessageId = 'user.group_new';
        icon = MapsUgcIcon;
        break;
      default:
        if (user != null) {
          userHeaderMessageId = 'user.title_login';
          values.name = user.name ?? '';
        } else {
          userHeaderMessageId = 'user.title_logout';
        }
        break;
    }
    return (
      <div className="flex items-center bg-slate-200 h-14 px-2 overflow-hidden">
        <div className="absolute left-0 top-0 h-14 w-14">
          {(user != null) && (
            <img
              src={user?.picture}
              alt="Profile"
              className="profile__avatar"
              referrerPolicy="no-referrer"
            />
          )}
        </div>
        {
          (user != null) && (
            <div className="absolute right-0 top-0 h-14 w-18 flex justify-center">
              <LogoutButton>
                <FormattedMessage id={'user.logout'} />
                <LogoutIcon />
              </LogoutButton>
            </div>
          )
        }
        <Component {...rest}
          headerMessageId={userHeaderMessageId}
          values={values}
          Icon={icon}
        />
      </div>
    );
  };

  return WithUserHeader;
};
const UserHeader = withUserHeader(Header);

export const UserLayout: FC = () => {
  return (
    <>
      <UserHeader />
      {/* headerMessageId="general.empty" */}
      <Menu />
      <Outlet />
    </>
  );
};
