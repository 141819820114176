/**
 * POST component
 *
 *
 * - Display customized post list [/post]
 * - Display category labels [/post/filter/{}]
 * - User can quickly post new post [/post/add]
 *
 * @todo:
 *  - create dynamic route to display group posts
 *
 */
import React, { type FC, useEffect } from 'react';
import {
  useParams,
  Link,
  redirect,
  useLoaderData,
  useLocation,
  type LoaderFunctionArgs,
} from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import store from './Store';
import Header from './Header';
import constants from '../constant';
import { type PostType } from './types';
import type { HeaderPropsType } from './Header';
import { BottomBrowseMenu } from './Menu';
// import { useForm, SubmitHandler } from "react-hook-form";

export const PostNew = (): JSX.Element => <h1>New Post</h1>;

const Posts = (): JSX.Element => {
  const params = useParams();
  return (
    <>
      <h1>-- Posts of {params.groupId} --</h1>
      {/* <Outlet /> */}
      <div>
        <Link to={`/group/${params.groupId}/post/new`}>Add new post</Link>
      </div>
      <div>
        <Link to={`/group/${params.groupId}/post/default`}>Default Post</Link>
      </div>
    </>
  );
};
const withPostHeader = (
  Component: FC<HeaderPropsType>
): FC<HeaderPropsType> => {
  const WithPostHeader = (props: HeaderPropsType): JSX.Element => {
    return (
      <div className="flex items-center bg-slate-200 h-14 px-2 overflow-hidden">
        <div className="w-12 text-center hover:text-red-900">
          <Link
            to={`/group/${props?.values?.groupId}`}
            className="flex items-center justify-center w-10 h-10 border-2  border-slate-100 rounded-full"
          >
            <KeyboardArrowLeftIcon />
          </Link>
        </div>
        <Component {...props} />
      </div>
    );
  };

  return WithPostHeader;
};
const PostHeader = withPostHeader(Header);

const Post: FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('Scroll to top');
  }, []);

  // const params = useParams();
  const location = useLocation();
  const postData = useLoaderData() as PostType;
  console.log(location.pathname);
  const postName = postData.subject;
  const body = '<p>' + postData.body.replaceAll('\n', '</p><p>') + '</p>';
  return (
    <div>
      <PostHeader
        headerClasses="bg-transparent w-full text-center p-4 mx-2"
        headerMessageId="general.post_header"
        values={{ name: postName, groupId: postData.groupId }}
      />
      {/* <div className='m-4'>
        { body }
      </div> */}
      <div
        className="m-4 pb-20"
        dangerouslySetInnerHTML={{
          __html: body,
        }}
      />
      <BottomBrowseMenu
        classes="(h-20) h-14 items-center (bg-slate-300) bg-slate-200 justify-between"
        backLink={`/group/${postData.groupId}/`}
      />
    </div>
  );
};

const postLoader = async ({
  params,
}: LoaderFunctionArgs): Promise<Response> => {
  const groupId = params.groupId ?? '';
  const postId = params.postId ?? '';
  const storedGroups = store.getWindowStoreItem(constants.STORE_GROUPS_NAME);
  const count = Object.keys(storedGroups).length;
  if (count !== 0) {
    console.log('group post from browser storage ...');
    const group = storedGroups[groupId];
    console.log('group:', groupId, group);
    if (group == null) {
      return redirect('/group');
    }
    let post$ = null;
    group.posts.forEach((post: PostType) => {
      console.log('post.id vs postId:', post.id, postId);
      if (post.id === parseInt(postId)) {
        console.log('post ====> ', post);
        post$ = post;
      }
    });
    if (post$ != null) {
      return post$;
    }
  }
  return redirect('/group');
};

export { postLoader, Posts, Post };

// type Inputs = {
//   example: string,
//   exampleRequired: string,
// };

// const PostForm: React.FC = () => {

//   const {
//     register,
//     handleSubmit,
//     watch,
//     formState: { errors }
//   } = useForm<Inputs>();

//   const onSubmit: SubmitHandler<Inputs> = data => console.log(data);

//   console.log(watch("example")) // watch input value by passing the name of it

//   return (
//     /* "handleSubmit" will validate your inputs before invoking "onSubmit" */
//     <form onSubmit={handleSubmit(onSubmit)}>
//       {/* register your input into the hook by invoking the "register" function */}
//       <input defaultValue="test" {...register("example")} />

//       {/* include validation with required or other standard HTML validation rules */}
//       <input {...register("exampleRequired", { required: true })} />
//       {/* errors will return when field validation fails  */}
//       {errors.exampleRequired && <span>This field is required</span>}

//       <input type="submit" />
//     </form>
//   );
// }
