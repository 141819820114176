/* eslint-disable @typescript-eslint/indent */
/**
 * icon page:
 *   https://mui.com/material-ui/material-icons/
 */
import React, { type MouseEventHandler, type FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import omit from 'lodash/omit';
import PersonIcon from '@mui/icons-material/Person';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import GridViewIcon from '@mui/icons-material/GridView';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import { NavLink as Link, type NavLinkProps, useMatch } from 'react-router-dom';
import { themeContext } from './Theme';
import { mergeClasses } from '../utils/tools';

const listDesign =
  'flex-1 rounded-md mt-2 mb-2 m-1 border-2 border-slate-100 flex justify-center items-center hover:border-slate-400';
const linkDesign =
  'flex flex-col justify-center items-center h-full w-full h-full hover:bg-slate-200 rounded-md';

const defaultClasses = 'w-full flex fixed bottom-0 h-20 bg-slate-300 px-2 z-10';

// eslint-disable-next-line @typescript-eslint/indent
const WithLinkWrapper = (
  LinkComponent: React.ForwardRefExoticComponent<
    NavLinkProps & React.RefAttributes<HTMLAnchorElement>
  >
): FC<{
  to: string;
  children: any;
  linkDesign: string;
}> => {
  const WithLink: FC<{
    to: string;
    children: any;
    linkDesign: string;
  }> = (props) => {
    const { to, linkDesign, children } = props;
    const match = useMatch(to.replace(/^\//, ''));
    // const [active, setActive] = useState(false)
    const newLinkDesign: string[] = linkDesign.split(/ +/);
    const ref = React.createRef<HTMLAnchorElement>();
    if (match === null) {
      omit(newLinkDesign, ['text-gray-500', 'bg-teal-200']);
    } else {
      newLinkDesign.push(...['text-gray-500', 'bg-teal-200']);
    }
    // console.log('=========', match, to, newLinkDesign, ref.current?.className);
    return (
      <LinkComponent
        to={to}
        className={newLinkDesign.join(' ')}
        // onClick={clickHandler}
        ref={ref}
      >
        {children}
      </LinkComponent>
    );
  };
  return WithLink;
};

const LinkWrapper = WithLinkWrapper(Link);

const Menu: FC = () => {
  const clickHandler: MouseEventHandler = (e) => {};
  return (
    <>
      {/* <L1Menu /> */}
      <themeContext.Consumer>
        {(value) => (
          <ul className={defaultClasses}>
            <li className={listDesign} key="news">
              <LinkWrapper to="/" linkDesign={linkDesign}>
                <ChatRoundedIcon onClick={clickHandler} />
                <FormattedMessage id="menu.news" />
              </LinkWrapper>
            </li>
            <li className={listDesign} key="group">
              <LinkWrapper to="/group" linkDesign={linkDesign}>
                <GridViewIcon onClick={clickHandler} />
                <FormattedMessage id="menu.group" />
              </LinkWrapper>
            </li>
            <li className={listDesign} key="target">
              <LinkWrapper to="/settings" linkDesign={linkDesign}>
                <CenterFocusStrongIcon />
                <FormattedMessage id="menu.target" />
              </LinkWrapper>
            </li>
            <li className={listDesign} key="publish">
              <LinkWrapper to="/user" linkDesign={linkDesign}>
                <PersonIcon />
                <FormattedMessage id="menu.publish" />
              </LinkWrapper>
            </li>
          </ul>
        )}
      </themeContext.Consumer>
    </>
  );
};

interface BottomMenuType {
  classes?: string;
  backLink: string;
}

export const BottomBrowseMenu: FC<BottomMenuType> = (props) => {
  const { classes = '', backLink } = props;
  const newClasses = mergeClasses(defaultClasses, classes);
  return (
    <>
      <div className={newClasses}>
        <div className="text-center hover:text-red-500">
          <Link
            to={backLink}
            className="flex items-center justify-center w-10 h-10 border-2  border-slate-100 rounded-full"
          >
            <KeyboardArrowLeftIcon />
          </Link>
        </div>
        <div className="text-center">
          <ScrollButton classes="flex items-center justify-center w-10 h-10 border-2  border-slate-100 rounded-full" />
        </div>
      </div>
    </>
  );
};

const ScrollButton: FC<{ classes: string }> = ({ classes }) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = (): void => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = (): void => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
      in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <button className={classes}>
      <VerticalAlignTopIcon
        onClick={scrollToTop}
        style={{ display: visible ? 'inline' : 'none' }}
      />
    </button>
  );
};

export { ScrollButton };

export default Menu;
