/**
 * https://formatjs.io/docs/getting-started/message-distribution
 * zh-CN, en-US
 */
import zh from './zh-CN.json';

const i18nConfig = {
  locale: 'zh-CN',
  messages: zh.messages,
};

export default i18nConfig;
