/* eslint-disable react/display-name */
import React, { type Ref, type PropsWithChildren, type LegacyRef, } from 'react';
import ReactDOM from 'react-dom';

interface BaseProps {
  className: string
  [key: string]: unknown
}
// type OrNull<T> = T | null

export const Button = React.forwardRef(
  (
    {
      className,
      active,
      reversed,
      ...props
    }: PropsWithChildren<
    {
      active: boolean
      reversed: boolean
    } & BaseProps
    >,
    ref: LegacyRef<HTMLSpanElement> | undefined
  ) => (
    <span
      {...props}
      ref={ref}
      className={'bg-slate-200'}
    />
  )
);

export const EditorValue = React.forwardRef(
  (
    {
      className,
      value,
      ...props
    }: PropsWithChildren<
    {
      value: any
    } & BaseProps
    >,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => {
    const textLines = value.document.nodes
      .map((node: any) => node.text)
      .toArray()
      .join('\n');
    return (
      <div
        ref={ref}
        {...props}
        className={''}
      >
        <div
          className={''}
        >
          Slate`s value as text
        </div>
        <div
          className={''}
        >
          {textLines}
        </div>
      </div>
    );
  }
);

export const Icon = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: LegacyRef<HTMLSpanElement> | undefined
  ) => (
    <span
      {...props}
      ref={ref}
      className={''}
    />
  )
);

export const Instruction = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => (
    <div
      {...props}
      ref={ref}
      className={''}
    />
  )
);

export const Menu = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => (
    <div
      {...props}
      data-test-id="menu"
      ref={ref}
      className={''}
    />
  )
);

export const Portal = ({ children, }: PropsWithChildren<BaseProps>) => {
  return typeof document === 'object'
    ? ReactDOM.createPortal(children, document.body)
    : null;
};

export const Toolbar = React.forwardRef(
  (
    { className, ...props }: PropsWithChildren<BaseProps>,
    ref: Ref<HTMLDivElement> | undefined
  ) => (
    <Menu
      {...props}
      ref={ref}
      className={''}
    />
  )
);
