import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { type SvgIcon } from '@mui/material';
import Control from './Control';
// import MenuIcon from '@mui/icons-material/Menu';

export interface HeaderPropsType {
  headerClasses?: string;
  headerMessageId?: string;
  values?: Record<string, any>;
  Icon?: typeof SvgIcon;
}
const Header: FC<HeaderPropsType> = (props: HeaderPropsType) => {
  const {
    headerClasses = '',
    headerMessageId = '',
    values = {},
    Icon = undefined,
  } = props;
  const defaultHeaderClasses = 'w-full text-center';
  const Message = (): React.ReactElement => {
    if (headerMessageId === '') {
      return <>Title</>;
    }
    return (
      <>
        {Icon != null && <Icon />}
        <FormattedMessage id={headerMessageId} values={values} />
      </>
    );
  };

  return (
    <div className="flex bg-slate-200 p-4 sticky top-0 z-20">
      <h1
        className={headerClasses !== '' ? headerClasses : defaultHeaderClasses}
      >
        <Message />
      </h1>
      <Control />
    </div>
  );
};

export default Header;
