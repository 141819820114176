import React, { type FC } from 'react';
import Menu from './Menu';
import Header from './Header';
import DashBoard from './DashBoard';

/**
 * Home page renders Header and Dashboard.
 */
const home: FC<unknown> = () => (
  <>
    <Header
      headerMessageId="general.home"
      values={{ name: new Date().getDay }}
    />
    <DashBoard />
    <Menu />
  </>
);
export default home;
