/**
 * Theme context is used to send styles to components on different
 * layers.
 *
 */
import React, {
  Component,
  createContext,
} from 'react';

export interface Themes {
  color: string
  bgColor: string

};

export interface ThemeProps {
  theme: Themes
  children: React.ReactNode
};

const themeContext = createContext({
  color: 'black',
  bgColor: 'white',
});

class ThemeProvider extends Component<ThemeProps> {
  render (): React.ReactNode {
    return (
      <themeContext.Provider value={this.props.theme}>
        { this.props.children }
      </themeContext.Provider>
    );
  }
}

export {
  ThemeProvider,
  themeContext,
};
